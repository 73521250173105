<template lang="pug">
#root
  md-app.app(:md-scrollbar="false")
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/cover-campaign-game.png")
        img.cover-mobile(src="@/assets/images/cover-campaign-game-mobile.jpg")
        p.cover-text
          | 2021 Google 游戏开发者峰会聚焦最新产品和解决方案、深入解读最佳实践案例，帮助您继续创造出色的游戏、与玩家建立联系并扩展您的业务。
      .fixed-container
        h2#videos.header-text(ref="videos")
          | 主题演讲精彩回放
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/15", target="_blank")
              .content
                h3.title
                  | 峰会开幕致辞和手机游戏主题演讲
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Greg Hartrell.jpg")
                    .details
                      h3.name
                        | Greg Hartrell
                      p
                        | Product Management Director, Google Play &#38; Android
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/16", target="_blank")
              .content
                h3.title
                  | 广告主题演讲：采用新的广告创新推动游戏业务持续增长
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/David Mitby.jpg")
                    .details
                      h3.name
                        | David Mitby
                      p
                        | Director, Product Management, App Ads, Google
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Chris Luhur.jpg")
                    .details
                      h3.name
                        | Chris Luhur
                      p
                        | Marketing Lead, Google for Games and AdMob, Google
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/17", target="_blank")
              .content
                h3.title
                  | 谷歌云主题演讲：后疫情时代的游戏行业
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Rob Martin.jpg")
                    .details
                      h3.name
                        | Rob Martin
                      p
                        | Chief Architect, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
        h2.header-text
          | 手机游戏分论坛回放
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/18", target="_blank")
              .content
                h3.title
                  | 推出 Android 游戏开发套件
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Scott Carbon-Ogden.jpg")
                    .details
                      h3.name
                        | Scott Carbon-Ogden
                      p
                        | Product Manager, Android
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/19", target="_blank")
              .content
                h3.title
                  | 用于虚拟工作室的 Android Game Development Extension
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Joshua Gilpatrick.png")
                    .details
                      h3.name
                        | Joshua Gilpatrick
                      p
                        | Software Engineer, Android Developer Tools
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/20", target="_blank")
              .content
                h3.title
                  | 用于 Android 游戏的 C/C++ 库
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Victor Repetskyi.png")
                    .details
                      h3.name
                        | Victor Repetskyi
                      p
                        | Tech Lead, Android Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/21", target="_blank")
              .content
                h3.title
                  | 利用 Android GPU Inspector 帧分析器优化图形
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Jay Kong.jpg")
                    .details
                      h3.name
                        | Jay Kong
                      p
                        | Product Manager, Android
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Francesco Carucci.jpg")
                    .details
                      h3.name
                        | Francesco Carucci
                      p
                        | Partner Developer Advocate, Games, Android
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/22", target="_blank")
              .content
                h3.title
                  | Android 12 游戏模式 API
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Katie Roberts-Hoffman.jpg")
                    .details
                      h3.name
                        | Katie Roberts-Hoffman
                      p
                        | Product Manager, Android Games &#38; Graphics
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/23", target="_blank")
              .content
                h3.title
                  | 推出 Play Integrity API
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Dom Elliott.jpg")
                    .details
                      h3.name
                        | Dom Elliott
                      p
                        | Product Manager, Google Play
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Solène Maître.png")
                    .details
                      h3.name
                        | Solène Maître
                      p
                        | Product Manager, Device Integrity, Android
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
        h2.header-text
          | 增长分论坛
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/24", target="_blank")
              .content
                h3.title
                  | 帮助减少玩家等待时间，留出更多游戏玩乐时间
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Yafit Becher.jpg")
                    .details
                      h3.name
                        | Yafit Becher
                      p
                        | Senior Product Manager, Google
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Vitor Baccetti.jpg")
                    .details
                      h3.name
                        | Vitor Baccetti
                      p
                        | Senior Product Manager, Google
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Andrew Giugliano.png")
                    .details
                      h3.name
                        | Andrew Giugliano
                      p
                        | Partner Developer Advocate, Google
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/25", target="_blank")
              .content
                h3.title
                  | 规划并优化能在 Google Play 上取得更大成功的游戏
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Lauren Mytton.jpg")
                    .details
                      h3.name
                        | Lauren Mytton
                      p
                        | Product Manager, Google Play
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/26", target="_blank")
              .content
                h3.title
                  | 提高商品详情的质量
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Bert de Weerd.jpg")
                    .details
                      h3.name
                        | Bert de Weerd
                      p
                        | Product Manager, App Content and Discovery UI, Google Play
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/27", target="_blank")
              .content
                h3.title
                  | 通过用户体验改进游戏
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Serena Shih.jpg")
                    .details
                      h3.name
                        | Serena Shih
                      p
                        | Strategic Partner Manager, Google Play
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/28", target="_blank")
              .content
                h3.title
                  | 深入了解受众：手机游戏玩家
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Hyun Woo.jpg")
                    .details
                      h3.name
                        | Hyun Woo
                      p
                        | Senior UX Researcher, Google
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/29", target="_blank")
              .content
                h3.title
                  | 战斗通行证主题演讲：顶级手机游戏开发者的见解和经验
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Aaron Hiscox.jpg")
                    .details
                      h3.name
                        | Aaron Hiscox
                      p
                        | Strategic Partner Manager, Google Play
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Frank Keienburg.jpg")
                    .details
                      h3.name
                        | Frank Keienburg
                      p
                        | Game Lead on Brawl Stars, Supercell
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Joel Joensuu.jpg")
                    .details
                      h3.name
                        | Joel Joensuu
                      p
                        | Game Designer on Empires & Puzzles, Small Giant Games
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/David Du.png")
                    .details
                      h3.name
                        | David Du
                      p
                        | Producer on State of Survival, FunPlus
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/30", target="_blank")
              .content
                h3.title
                  | 开发者从 PC/主机转向手机的游戏开发历程
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Ranjodh Mathial.jpg")
                    .details
                      h3.name
                        | Ranjodh Mathial
                      p
                        | Strategic Partner Manager, Google Play
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Michael Chow.jpg")
                    .details
                      h3.name
                        | Michael Chow
                      p
                        | Executive Producer - Wild Rift, Riot Games
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Tyler Nation.jpg")
                    .details
                      h3.name
                        | Tyler Nation
                      p
                        | VP, Head of Mobile, 2K
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Chris Cao.jpg")
                    .details
                      h3.name
                        | Chris Cao
                      p
                        | Executive Producer, Wizards of the Coast
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
        h2.header-text
          | 基础架构分论坛
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/31", target="_blank")
              .content
                h3.title
                  | 利用 Game Servers 在全球提供无缝的多人游戏体验
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Mark Mandel.png")
                    .details
                      h3.name
                        | Mark Mandel
                      p
                        | Developer Advocate, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/32", target="_blank")
              .content
                h3.title
                  | 用于游戏的云原生数据库和存储空间
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Mike Nadal.png")
                    .details
                      h3.name
                        | Mike Nadal
                      p
                        | Customer Engineer, Google Cloud for Gamesa
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Emma Haruka Iwao.jpg")
                    .details
                      h3.name
                        | Emma Haruka Iwao
                      p
                        | Developer Advocate, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/33", target="_blank")
              .content
                h3.title
                  | Planet Scale 2.0：云游戏是成功之道
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Sebastian Weigand.jpg")
                    .details
                      h3.name
                        | Sebastian Weigand
                      p
                        | Systems Architect, Google Cloud for Games
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Susan Romera.jpg")
                    .details
                      h3.name
                        | Susan Romera
                      p
                        | Customer Engineer, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/34", target="_blank")
              .content
                h3.title
                  | 利用机器学习吸引多样化的新受众
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Patrick Smith.jpg")
                    .details
                      h3.name
                        | Patrick Smith
                      p
                        | Machine Learning Specialist, Google Cloud for Games
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Vishu Cheruku.jpg")
                    .details
                      h3.name
                        | Vishu Cheruku
                      p
                        | Customer Engineer, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/35", target="_blank")
              .content
                h3.title
                  | 将游戏工作室搬到云端
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Adrian Graham.jpg")
                    .details
                      h3.name
                        | Adrian Graham
                      p
                        | Senior Cloud Solutions Architect, Google Cloud for Games
                  .speaker
                    img.avatar(src="@/assets/images/game_avatars/Travis Williams.jpg")
                    .details
                      h3.name
                        | Travis Williams
                      p
                        | Enterprise Cloud Architect, Google Cloud for Games
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
      .footer
        p
          | 如果您也希望尝试谷歌广告解决方案拓展您的海外业务，现在即可致电谷歌专家。
          br.mobile
          | 新用户还可领取最高 400 元优惠券，助力轻松开启谷歌广告之旅。
          br
          | 欢迎拨打：400 - 819 - 1244
          br
          | 工作日：9:00 - 18:00
        md-divider
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(
    :class="{ 'share-mobile': isShare }",
    @click="() => { isShare = false; }"
  )
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(
        :class="{ 'share-pc': isShare }",
        @click="() => { isShare = false; }"
      )
        img(src="~@/assets/images/share-pc.png", width="150px")
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
export default {
  name: "Game",
  components: {},
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        window.location.assign(uri);
        // const ua = navigator.userAgent.toLowerCase();
        // if (
        //   (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        //   ua.indexOf("micromessenger") !== -1
        // ) {
        //   window.location.assign(uri);
        // } else {
        //   window.open(uri, "_blank");
        // }
      });
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "home", source: window.location.search });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

.header-split {
  width: 100%;
  height: 3px;
  margin-top: 8px;
  background: #448aff;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }

  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
  }

  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right: 10px;
}

.arrow-forward {
  margin-left: 10px;
}

.arrow-icon {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.active {
    background-color: #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  width: 100%;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.fixed-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-container {
  padding: 10px 0 50px 0;

  @media screen and (min-width: 1280px) {
    padding: 50px 200px;
  }

  background: #fff;
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding: 0 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding: 16px 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 16px 50px;
    font-size: 20px;
  }

  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 10px auto 60px auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 20px 0 0 0;
    // margin: 20px auto 0 auto; 如果需要左右居中，使用此行
    height: 20px;
    display: block;
  }
}

.video-card {
  display: flex;
  margin: 16px 5px 16px 20px;
  padding: 10px 5px;

  @media screen and (min-width: 1280px) {
    margin: 16px 50px 16px 50px;
    padding: 10px 5px 10px 80px;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  h3 {
    text-align: left;
    color: #000;
  }

  .title  {
    font-weight: normal;
    font-size: 20px;
    margin: 5px 0 30px 0;
  }

  .name {
    margin: 0;
  }

  .content {
    flex: 1;
    .content-details {
      @media screen and (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .speaker {
        display: flex;
        flex: 1;
        padding: 20px 0;
        @media screen and (min-width: 1280px) {
          padding: 0;
        }
        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 32px;
        }
        .details {
          flex: 1;
          margin: 0 20px;
          color: #858585;
        }
      }
    }
  }

  .btn-play {
    width: 64px;
  }
}

.video-card:nth-child(even) {
  background: #ffffff;
}

.video-card:nth-child(odd) {
  background: #f8f9fb;
}

h3.title {
  line-height: 1.4;
}
</style>
